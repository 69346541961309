import {CommonModule, DecimalPipe} from '@angular/common';
import {HttpClientJsonpModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {MatBadgeModule} from '@angular/material/badge';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatTableModule} from '@angular/material/table';
import {RouterModule} from '@angular/router';
import {AutoFocusDirective} from '@application/directives/auto-focus.directive';
import {DecimalDirective} from '@application/directives/decimal.directive';
import {FieldBlankInputDirective} from '@application/directives/field-blank-input.directive';
import {HasPermissionDirective} from '@application/directives/has-permission.directive';
import {InputChineseDirective} from '@application/directives/input-chinese.directive';
import {InputL10nDecimalDirective} from '@application/directives/input-l10n-decimal.directive';
import {InputTimeDirective} from '@application/directives/input-time.directive';
import {InputWholeNumberDirective} from '@application/directives/input-whole-number.directive';
import {RemoveAriaOwnsDirective} from '@application/directives/remove-aria-owns.directive';
import {ResetToZeroDirective} from '@application/directives/reset-input-to-zero.directive';
import {TooltipDirective} from '@application/directives/tooltip.directive';
import {DataAvailabilityPipe} from '@application/pipes/data-availability.pipe';
import {DatePipe} from '@application/pipes/date.pipe';
import {HighlightSearchPipe} from '@application/pipes/highlight-search.pipe';
import {MomentDatePipe} from '@application/pipes/moment-date.pipe';
import {NotificationIconPipe} from '@application/pipes/notification-icon.pipe';
import {NotificationTitlePipe} from '@application/pipes/notification-title.pipe';
import {PointReplacerPipe} from '@application/pipes/point-replacer.pipe';
import {StatusClassPipe} from '@application/pipes/status-class.pipe';
import {StatusTitlePipe} from '@application/pipes/status-title.pipe';
import {UserCodeNamePipe} from '@application/pipes/user-code-name.pipe';
import {LanguageService} from '@application/translation/language/language.service';
import {TranslationModule} from '@application/translation/translation.module';
import {AdvancedDateFilterDialogComponent} from '@presentation/components/advanced-search-filters/advanced-date-filter-dialog/advanced-date-filter-dialog.component';
import {AdvancedSearchFilterDialogComponent} from '@presentation/components/advanced-search-filters/advanced-search-filter-dialog/advanced-search-filter-dialog.component';
import {AdvancedSearchComponent} from '@presentation/components/advanced-search-filters/advanced-search.component';
import {OverlayActionsService} from '@presentation/components/overlay/overlay/overlay-actions.service';
import {ServerSideDropDownComponent} from '@presentation/components/server-side-drop-down/server-side-drop-down.component';
import {TabsMenuCardComponent} from '@presentation/components/tabs-menu-card/tabs-menu-card.component';
import {AuthenticationTemplateComponent} from '@presentation/pages/authentication/authentication-template.component';
import {DateTimeRendererComponent} from '@presentation/pages/overview/date-time-renderer/date-time-renderer.component';
import {GridCheckBoxComponent} from '@presentation/pages/overview/grid-checkbox-component/grid-checkbox-component';
import {OverviewComponent} from '@presentation/pages/overview/overview.component';
import {PaginationComponent} from '@presentation/pages/overview/pagination/pagination.component';
import {StatusRendererComponent} from '@presentation/pages/overview/status-renderer/status-renderer.component';
import {UserCodeNameRendererComponent} from '@presentation/pages/overview/user-code-name-renderer/user-code-name-renderer.component';
import {HyperlinkComponent} from '@presentation/pages/sales/component/cell-renderer-component/hyperlink-renderer.component';
import {TemplateBaseComponent} from '@presentation/pages/template-base.component';
import {AgGridModule} from 'ag-grid-angular';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {CountdownModule} from 'ngx-countdown';
import {CountUpModule} from 'ngx-countup';
import {NgxDocViewerModule} from 'ngx-doc-viewer';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {MaterialModule} from '../material.module';
import {AlertDialogComponent} from '../presentation/components/alert-dialog/alert-dialog.component';
import {CountDownButtonComponent} from '../presentation/components/countdown-button/countdown-button.component';
import {CustomMessageToastService} from '../presentation/components/custom-message-toast/custom-message-toast.service';
import {DatepickerHeaderComponent} from '../presentation/components/datepicker-header/datepicker-header.component';
import {EllipsisLabelComponent} from '../presentation/components/ellipsis-label/ellipsis-label.component';
import {FilterComponent} from '../presentation/components/filter/filter.component';
import {GridTooltipComponent} from '../presentation/components/grid/grid-tooltip/grid-tooltip.component';
import {GridHeaderNameComponent} from '../presentation/components/grid/header-name/grid-header-name.component';
import {GridNumberInputComponent} from '../presentation/components/grid/number-input/grid-number-input.component';
import {NumericEditorComponent} from '../presentation/components/grid/numeric-editor/numeric-editor.component';
import {InvalidFormFieldMessageComponent} from '../presentation/components/invalid-form-field-message/invalid-form-field-message.component';
import {InvalidFormMessageComponent} from '../presentation/components/invalid-form-message/invalid-form-message.component';
import {LinkFormInputComponent} from '../presentation/components/link-form-input/link-form-input.component';
import {LoadingFileComponent} from '../presentation/components/loading-file/loading-file.component';
import {LoadingOverlayComponent} from '../presentation/components/overlay/loading-overlay/loading-overlay.component';
import {NoDataOverlayComponent} from '../presentation/components/overlay/no-data-overlay/no-data-overlay.component';
import {ProgressButtonComponent} from '../presentation/components/progress-button/progress-button.component';
import {ProgressSpinnerComponent} from '../presentation/components/progress-spinner/progress-spinner.component';
import {RepositionDialogComponent} from '../presentation/components/reposition-dialog/reposition-dialog.component';
import {SaMessageToastComponent} from '../presentation/components/sa-message-toast/sa-message-toast.component';
import {AddressDialogComponent} from './components/address-dialog/address-dialog.component';
import {RemarkStatusDialogComponent} from './components/remark-status-dialog/remark-status-dialog.component';
import {TooltipComponent} from './components/tooltip/tooltip.component';
import {FilePreviewComponent} from './components/upload-files/file-preview/file-preview.component';
import {UploadFilesComponent} from './components/upload-files/upload-files.component';
import {MY_DATE_FORMATS} from './constant/date-picker-format';
import {SearchPipe} from './pipes/search.pipe';
import {ThemeConstantService} from './services/theme-constant.service';
import {VdwCardComponent} from './template/content/vdw-card/vdw-card.component';
import {VdwTitleComponent} from './template/content/vdw-title/vdw-title.component';

@NgModule({
  exports: [
    PdfViewerModule,
    NgxDocViewerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientJsonpModule,
    NzIconModule,
    PerfectScrollbarModule,
    SearchPipe,
    TranslationModule,
    MaterialModule,
    MatBadgeModule,
    AlertDialogComponent,
    FilterComponent,
    InvalidFormFieldMessageComponent,
    InvalidFormMessageComponent,
    LinkFormInputComponent,
    LoadingFileComponent,
    CountDownButtonComponent,
    ProgressButtonComponent,
    ProgressSpinnerComponent,
    EllipsisLabelComponent,
    HighlightSearchPipe,
    GridHeaderNameComponent,
    LoadingOverlayComponent,
    NoDataOverlayComponent,
    GridNumberInputComponent,
    GridTooltipComponent,
    OverviewComponent,
    VdwTitleComponent,
    VdwCardComponent,
    StatusRendererComponent,
    DateTimeRendererComponent,
    UserCodeNameRendererComponent,
    PaginationComponent,
    UserCodeNamePipe,
    DataAvailabilityPipe,
    MomentDatePipe,
    StatusTitlePipe,
    StatusClassPipe,
    NotificationTitlePipe,
    NotificationIconPipe,
    DatePipe,
    DatepickerHeaderComponent,
    RepositionDialogComponent,
    AdvancedDateFilterDialogComponent,
    AdvancedSearchFilterDialogComponent,
    AdvancedSearchComponent,
    AutoFocusDirective,
    HasPermissionDirective,
    RemoveAriaOwnsDirective,
    InputL10nDecimalDirective,
    InputTimeDirective,
    SaMessageToastComponent,
    TemplateBaseComponent,
    AuthenticationTemplateComponent,
    TabsMenuCardComponent,
    DecimalDirective,
    UploadFilesComponent,
    FilePreviewComponent,
    FieldBlankInputDirective,
    TooltipComponent,
    TooltipDirective,
    InputChineseDirective,
    RemarkStatusDialogComponent,
    ServerSideDropDownComponent,
    MatTableModule,
    InputWholeNumberDirective,
    HyperlinkComponent,
    AddressDialogComponent,
    GridCheckBoxComponent,
    ResetToZeroDirective
  ],
  imports: [
    PdfViewerModule,
    NgxDocViewerModule,
    RouterModule,
    CommonModule,
    NzIconModule,
    NzToolTipModule,
    PerfectScrollbarModule,
    TranslationModule,
    MaterialModule,
    MatBadgeModule,
    ReactiveFormsModule,
    FormsModule,
    AgGridModule.withComponents([GridHeaderNameComponent, LoadingOverlayComponent, NoDataOverlayComponent, GridNumberInputComponent, GridTooltipComponent]),
    CountUpModule,
    CountdownModule,
    MatTableModule
  ],
  declarations: [
    GridHeaderNameComponent,
    LoadingOverlayComponent,
    NoDataOverlayComponent,
    GridNumberInputComponent,
    GridTooltipComponent,
    AlertDialogComponent,
    SearchPipe,
    FilterComponent,
    InvalidFormFieldMessageComponent,
    InvalidFormMessageComponent,
    LinkFormInputComponent,
    LoadingFileComponent,
    CountDownButtonComponent,
    ProgressButtonComponent,
    ProgressSpinnerComponent,
    EllipsisLabelComponent,
    HighlightSearchPipe,
    OverviewComponent,
    VdwTitleComponent,
    VdwCardComponent,
    StatusRendererComponent,
    DateTimeRendererComponent,
    UserCodeNameRendererComponent,
    PaginationComponent,
    UserCodeNamePipe,
    DataAvailabilityPipe,
    MomentDatePipe,
    StatusTitlePipe,
    StatusClassPipe,
    NotificationTitlePipe,
    NotificationIconPipe,
    DatePipe,
    PointReplacerPipe,
    DatepickerHeaderComponent,
    NumericEditorComponent,
    RepositionDialogComponent,
    AdvancedDateFilterDialogComponent,
    AdvancedSearchFilterDialogComponent,
    AdvancedSearchComponent,
    AutoFocusDirective,
    HasPermissionDirective,
    RemoveAriaOwnsDirective,
    InputL10nDecimalDirective,
    InputTimeDirective,
    SaMessageToastComponent,
    TemplateBaseComponent,
    AuthenticationTemplateComponent,
    TabsMenuCardComponent,
    DecimalDirective,
    UploadFilesComponent,
    FilePreviewComponent,
    FieldBlankInputDirective,
    TooltipComponent,
    TooltipDirective,
    InputChineseDirective,
    RemarkStatusDialogComponent,
    ServerSideDropDownComponent,
    InputWholeNumberDirective,
    HyperlinkComponent,
    AddressDialogComponent,
    GridCheckBoxComponent,
    ResetToZeroDirective
  ],
  providers: [
    DecimalPipe,
    ThemeConstantService,
    PointReplacerPipe,
    CustomMessageToastService,
    OverlayActionsService,
    {provide: MAT_DATE_LOCALE, useValue: LanguageService.DEFAULT_LANGUAGE},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS}
  ]
})
export class SharedModule {}
