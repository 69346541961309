export enum Permission {
  VIEW = 1,
  ADD,
  EDIT,
  DELETE,
  EXPORT,
  VERIFY,
  EDIT_AX_QUANTITY,
  INCLUDE_OEM_CUSTOMER,
  DELIVER_PARCEL,
  SYNC,
  DOWNLOAD,
  UPDATE_DISCOUNT_AND_PRICE_ADJUSTMENT_RATE,
  UPDATE_DELIVERY_STATUS,
  VIEW_TRANSLATION,
  EDIT_TRANSLATION,
  VIEW_CURRENCY_RATE,
  ADD_CURRENCY_RATE,
  EDIT_CURRENCY_RATE,
  DELETE_CURRENCY_RATE,
  VIEW_ORDER_DELIVERY,
  ADD_ORDER_DELIVERY,
  EDIT_ORDER_DELIVERY,
  COPY
}
