import {Injectable} from '@angular/core';
import {EnumUtils} from '@application/helper/enum/enum-utils';
import {ITranslationAmount} from '@infrastructure/http/translation-amount.interface';
import {ITranslationDescription} from '@infrastructure/translation-description.interface';
import {ITranslationName} from '@infrastructure/translation-name.interface';
import {LocalStorageService} from 'angular-2-local-storage';
import {includes, isEmpty, isNil, map} from 'lodash-es';
import {moment} from '../../common/moment';
import {TranslateService} from '../translate.service';
import {Language} from './language.enum';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public static readonly LOCAL_STORAGE_KEY = 'language';
  public static readonly AVAILABLE_LANGUAGES: string[] = [Language.ZH, Language.EN];
  public static readonly DEFAULT_LANGUAGE: string = Language.ZH;
  private static readonly EXPERIMENTAL_LANGUAGES = Language.ZH;

  private readonly translate: TranslateService;
  private readonly localStorage: LocalStorageService;

  public constructor(translate: TranslateService, localStorage: LocalStorageService) {
    this.translate = translate;
    this.localStorage = localStorage;
  }

  public initialize(): Promise<void> {
    let storedLanguage: string = this.localStorage.get(LanguageService.LOCAL_STORAGE_KEY);

    if (isNil(storedLanguage) || isEmpty(storedLanguage) || isNil(EnumUtils.getKeyFromValue(Language, storedLanguage))) {
      storedLanguage = LanguageService.DEFAULT_LANGUAGE;
      this.localStorage.set(LanguageService.LOCAL_STORAGE_KEY, storedLanguage);
    }

    moment.locale(storedLanguage);
    return this.setLanguage(storedLanguage);
  }

  public changeLanguage(language: string): Promise<void> {
    this.localStorage.set(LanguageService.LOCAL_STORAGE_KEY, language);
    moment.locale(language);
    return this.setLanguage(language);
  }

  public getActiveLanguage(): string {
    return Language[this.translate.getLocale().language.toUpperCase()];
  }

  public getAvailableLanguages(): {key: string; label: string}[] {
    return map(LanguageService.AVAILABLE_LANGUAGES, (language: string) => {
      const key = EnumUtils.getKeyFromValue(Language, language);
      return {
        key: language,
        label: this.translate.instant(`GENERAL.LANGUAGE.${key}`)
      };
    });
  }

  public isExperimentalLanguage(language: string): boolean {
    return includes(LanguageService.EXPERIMENTAL_LANGUAGES, language);
  }

  public getTranslatedValue(value: any): string {
    if (isNil(value)) return null;
    const isTranslationName = (response: any): response is ITranslationName => {
      return 'chineseName' in response && 'englishName' in response;
    };

    const isTranslationDescription = (response: any): response is ITranslationDescription => {
      return 'chineseDescription' in response && 'englishDescription' in response;
    };

    const isTranslationAmount = (response: any): response is ITranslationAmount => {
      return 'chineseAmount' in response && 'englishAmount' in response;
    };

    if (isTranslationName(value)) {
      switch (this.getActiveLanguage()) {
        case Language.EN:
          return value.englishName;
        case Language.ZH:
          return value.chineseName;
      }
    } else if (isTranslationDescription(value)) {
      switch (this.getActiveLanguage()) {
        case Language.EN:
          return value.englishDescription;
        case Language.ZH:
          return value.chineseDescription;
      }
    } else if (isTranslationAmount(value)) {
      switch (this.getActiveLanguage()) {
        case Language.EN:
          return value.englishAmount;
        case Language.ZH:
          return value.chineseAmount;
      }
    }
  }

  public getTranslatedValueByField(field: string, object: any): string | any {
    if (!isNil(object)) {
      switch (this.getActiveLanguage()) {
        case Language.EN:
          return object[`${field}En`];
        case Language.ZH: {
          let value = object[`${field}Zh`]; // Try to retrieve the value for the specified field in Chinese.
          if (isNil(value)) {
            value = object[field]; // If the Chinese value is not available, fall back to the default field value.
          }
          return value; // Return the resolved value for Chinese or the default value.
        }
      }
    }
  }

  private setLanguage(language: string): Promise<void> {
    return this.translate.setLocale({language: EnumUtils.getKeyFromValue(Language, language).toLowerCase()});
  }
}
