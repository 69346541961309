import {DecimalPipe} from '@angular/common';
import {Directive, ElementRef, HostListener, Input, Renderer2} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {isNil} from 'lodash-es';

@Directive({
  selector: '[appDecimal]'
})
export class DecimalDirective {
  @Input() control: AbstractControl;
  @Input() decimalPlaces = 4;
  @Input() includeComma = true;
  @Input() allowNegative = false;
  private regexExpression = new RegExp('[.,]', 'g');
  private decimalSeparator = '.';

  private elementRef: ElementRef;
  private renderer: Renderer2;
  private decimalPipe: DecimalPipe;

  constructor(elementRef: ElementRef, renderer: Renderer2, decimalPipe: DecimalPipe) {
    this.elementRef = elementRef;
    this.renderer = renderer;
    this.decimalPipe = decimalPipe;
  }

  @HostListener('input', ['$event']) onInput(event: any): void {
    if (event.target.value.includes('.')) {
      const [integer, fraction = ''] = (event.target.value || '').toString().split(this.decimalSeparator);

      const newInteger = this.transform(integer);
      const newFraction = this.decimalSeparator + fraction.substring(0, this.decimalPlaces);
      const cursorPosition = this.selectCursorPosition(integer, newInteger);

      this.renderer.setProperty(this.elementRef.nativeElement, 'value', newInteger + newFraction);
      this.control.setValue(newInteger + newFraction);
      if (this.checkIsInteger()) {
        this.elementRef.nativeElement.selectionStart = cursorPosition;
        this.elementRef.nativeElement.selectionEnd = cursorPosition;
      }
    } else {
      const integer = this.transform(event.target.value);
      this.control.setValue(integer);
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', integer);
    }
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: any): void {
    const key = event.which || event.keyCode || 0;
    if (key === 45) {
      if (this.allowNegative) {
        return;
      }
      event.preventDefault();
    } else if (key === 8) {
      event.preventDefault();
    } else if (key !== 46 && key > 31 && (key < 48 || key > 57)) {
      event.preventDefault();
    }
  }

  @HostListener('blur', ['$event']) onBlur(event: any): void {
    let numberOfZeros = '';
    for (let i = 0; i < this.decimalPlaces; i++) {
      numberOfZeros += '0';
    }
    if (!isNil(event.target.value) && event.target.value !== '') {
      const negativePattern = /^-0*\.?0*$/;
      if (this.allowNegative && negativePattern.test(event.target.value)) {
        this.control.setValue(null);
      } else if (event.target.value.includes('.')) {
        const [integer, fraction = ''] = event.target.value.toString().split(this.decimalSeparator);
        const newFraction = fraction.length !== this.decimalPlaces ? this.decimalSeparator + (fraction + numberOfZeros).substring(0, this.decimalPlaces) : this.decimalSeparator + fraction;
        this.control.setValue(integer + newFraction);
      } else {
        const newFraction = this.decimalSeparator + numberOfZeros;
        this.control.setValue(event.target.value + newFraction);
      }
    }
  }

  private transform(val: string): string {
    const format = parseInt(val.replace(this.regexExpression, ''), 10);
    if (this.includeComma) {
      if (this.allowNegative) {
        var valueWithDash = val
          .replace(this.regexExpression, '')
          .replace(/^(-)|-+/g, '$1') // keeps hyphen at the start of the strings and remove all other hyphens
          .replace(/^(-)?0*(\d+\.?\d*)$/, '$1$2');
        return valueWithDash;
      }
      return this.decimalPipe.transform(format, '1.0', 'en-US');
    } else {
      return isNaN(format) ? null : format.toString();
    }
  }

  private selectCursorPosition(integer: string, newInteger: string): number {
    if (integer.length !== newInteger.length) {
      return this.elementRef.nativeElement.selectionStart + 1;
    } else {
      return this.elementRef.nativeElement.selectionStart;
    }
  }

  private checkIsInteger(): boolean {
    return this.elementRef.nativeElement.selectionStart <= this.elementRef.nativeElement.value.indexOf('.');
  }
}
