import {AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {Router} from '@angular/router';
import {IModulePermission} from '@application/directives/has-permission.directive';
import {AgGridRowSelection} from '@application/helper/ag-grid-row-selection.enum';
import {ModuleUtils} from '@application/helper/module-utils';
import {PageUrls} from '@domain/ag-grid/page-urls';
import {Translations} from '@domain/ag-grid/translations';
import {Module} from '@domain/organization/role/module.enum';
import {Permission} from '@domain/organization/role/permission.enum';
import {BaseComponent} from '@presentation/base-component';
import {IAdvancedSearchOptionFilter} from '@presentation/components/advanced-search-filters/advanced-search-option-filter/advanced-search-option-filter.option';
import {IAdvancedSearchFilter} from '@presentation/components/advanced-search-filters/advanced-search/advanced-search-filter';
import {FilterComponent} from '@presentation/components/filter/filter.component';
import {GridTooltipComponent} from '@presentation/components/grid/grid-tooltip/grid-tooltip.component';
import {LoadingOverlayComponent} from '@presentation/components/overlay/loading-overlay/loading-overlay.component';
import {NoDataOverlayComponentParams} from '@presentation/components/overlay/no-data-overlay/no-data-overlay-component-params';
import {NoDataOverlayComponent} from '@presentation/components/overlay/no-data-overlay/no-data-overlay.component';
import {EnhancementControlService} from '@shared/services/enhancement-control.service';
import {AgGridEvent, GridOptions, GridReadyEvent, IServerSideDatasource, RowClickedEvent} from 'ag-grid-community';
import {camelCase, filter, isEmpty, isEqual, isNil, replace} from 'lodash-es';
import {OverviewColDef} from './overview-col-def';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent extends BaseComponent implements OnInit, AfterContentChecked {
  @Input() public module: Module;
  @Input() public moduleCategoryTitle: string;
  @Input() public moduleTitle: string;
  @Input() public buttonIcon: string;
  @Input() public buttonTitle: string;
  @Input() public showAddButton = true;
  @Input() public detailIcon: string;
  @Input() public listOfData: any[] | null;
  @Input() public columnDefs: OverviewColDef[];
  @Input() public advancedSearchFilters: IAdvancedSearchFilter[] = [];
  @Input() public hideDescription = false;
  @Input() public showSideNav = true;
  @Input() public showSearchFilter = true;
  @Input() public gridDataSource: IServerSideDatasource;
  @Input() public advancedSearchOptionFilters: IAdvancedSearchOptionFilter[] = [];
  @Input() public isShowDialog = false;
  @Input() public rowBuffer = 10;
  @Input() public enableRowClickEvent = true;
  @Input() public showCustomizedButton = false;
  @Input() public isAlphanumericValidation = false;
  @Input() public pageSizeOptions: number[] = [10, 15, 20, 25, 50];
  @Input() public isEditable = true;

  @Output() public rowsSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() public reloadData: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('sidenav') public sidenav: MatSidenav;
  @ViewChild('searchFilter') public searchFilter: FilterComponent;
  public gridOptions: GridOptions;
  public selectedItem: any;
  public translations: Translations;
  public canShowPagination = false;
  public canShowFooter = false;
  public showGeneralFilter: boolean;
  public paginationPageSize = 10;
  public addPermission: IModulePermission;
  public viewPermission: IModulePermission;

  private readonly _router: Router;
  private readonly _changeDetectorRef: ChangeDetectorRef;
  private _pageUrls: PageUrls;
  private _detailTitle: string;
  private _enhancementControlService: EnhancementControlService;

  public constructor(router: Router, changeDetectorRef: ChangeDetectorRef, enhancementControlService: EnhancementControlService) {
    super();
    this._router = router;
    this._changeDetectorRef = changeDetectorRef;
    this._enhancementControlService = enhancementControlService;
  }

  public ngOnInit(): void {
    const module = camelCase(Module[this.module]);
    this._pageUrls = ModuleUtils.getPageUrls(module);
    this.translations = ModuleUtils.getTranslations(module);
    this.addPermission = {module: this.module, requiredPermission: Permission.ADD};
    this.viewPermission = {module: this.module, requiredPermission: Permission.VIEW};
    this.showGeneralFilter = !this._enhancementControlService.isPaginationEnabled;
    this.initialiseGridOptions();
  }

  public ngAfterContentChecked(): void {
    this._changeDetectorRef.detectChanges();
  }

  public get detailTitle(): string {
    return this._detailTitle;
  }

  public set detailTitle(detailTitle: string) {
    this._detailTitle = detailTitle;
  }

  public filter(event: string): void {
    if (this._enhancementControlService.isPaginationEnabled) {
      this.gridOptions.api.onFilterChanged();
    } else {
      this.gridOptions.api.setQuickFilter(event);
      if (this.gridOptions.api.getDisplayedRowCount() < 1) {
        this.gridOptions.api.showNoRowsOverlay();
      } else {
        this.gridOptions.api.hideOverlay();
      }
    }
  }

  public ensureIndexIsVisible(index: number): void {
    this.gridOptions.api.ensureIndexVisible(index);
  }

  public navigateToAddPage(): void {
    this._router.navigateByUrl(this._pageUrls['add']);
  }

  public navigateToDetailsPage(): void {
    this._router.navigateByUrl(replace(this._pageUrls['details'], ':id', String(this.selectedItem.id)));
  }

  public getPageUrl(type: string): string {
    return this._pageUrls[type];
  }

  public canShowSidebar(): boolean {
    return !isNil(this.selectedItem);
  }

  public hideSidebar(): void {
    this.selectedItem = null;
    this.sidenav?.close();
    this.gridOptions.api.deselectAll();
  }

  public searchFiltersChanged(): void {
    if (this._enhancementControlService.isPaginationEnabled) {
      this.gridOptions.api.onFilterChanged();
    } else {
      this.reloadData.emit();
    }
  }

  public get canShowAdvancedSearch(): boolean {
    return !isEmpty(this.advancedSearchFilters);
  }

  private initialiseGridOptions(): void {
    this.gridOptions = <GridOptions>{
      animateRows: false,
      defaultColDef: {
        sortable: true,
        resizable: true,
        minWidth: 150,
        tooltipComponentFramework: GridTooltipComponent,
        headerClass: (params: any) => {
          const firstColumnId = params.column.columnApi.getColumnState()[0].colId;
          return params.column.getColDef().field !== firstColumnId ? 'ag-border' : '';
        },
        cellClass: (params: any) => {
          const firstColumnId = params.columnApi.getColumnState()[0].colId;
          return params.colDef.field !== firstColumnId ? 'ag-border' : '';
        }
      },
      rowBuffer: this.rowBuffer,
      columnDefs: this.columnDefs,
      headerHeight: 42,
      onGridSizeChanged: () => this.resizeGrid(),
      onGridReady: (params: GridReadyEvent) => this.onGridReadyEvent(params),
      onRowClicked: (event: RowClickedEvent) => {
        if (window.getSelection().type !== 'Range') {
          if (!this.isShowDialog) {
            this.handleNonDialogSelection(event);
          } else if (!isNil(event) && !this.isEditable) {
            return;
          } else {
            this.handleSelectionEvent(event);
          }
        } else {
          this.hideSidebar();
        }
      },
      isRowSelectable: (rowNode) => this.isEditable,
      enableCellTextSelection: true,
      onSortChanged(event: AgGridEvent) {
        event.api.refreshCells();
      },
      suppressContextMenu: true,
      suppressScrollOnNewData: true,
      suppressHorizontalScroll: false,
      suppressMovableColumns: true,
      suppressPaginationPanel: true,
      loadingOverlayComponentFramework: LoadingOverlayComponent,
      noRowsOverlayComponentFramework: NoDataOverlayComponent,
      noRowsOverlayComponentParams: <NoDataOverlayComponentParams>{
        hideDescription: this.hideDescription,
        titleParam: this.buttonTitle,
        filterDescriptionParam: {
          paramKeyCreate: ''
        },
        isAnyFilterPresent: () => this.gridOptions.api?.isAnyFilterPresent() || !isEmpty(filter(this.advancedSearchFilters, ['isSelected', true]))
      },
      rowClass: 'normal',
      rowHeight: 40,
      rowSelection: AgGridRowSelection.SINGLE,
      domLayout: 'normal',
      paginationPageSize: this.paginationPageSize
    };

    if (this._enhancementControlService.isPaginationEnabled) {
      this.gridOptions.rowModelType = 'serverSide';
      this.gridOptions.serverSideStoreType = 'partial';
      this.gridOptions.cacheBlockSize = 50;

      this.gridOptions.serverSideDatasource = this.gridDataSource;
    }
  }

  private resizeGrid(): void {
    if (!isNil(this.gridOptions)) {
      this.gridOptions.api.sizeColumnsToFit();
    }
  }

  private onGridReadyEvent(params: GridReadyEvent): void {
    this.gridOptions.api = params.api;
    this.gridOptions.api.sizeColumnsToFit();
  }

  public handleSelectionEvent(event: any): void {
    const item: any = event.node.data;
    this.selectedItem = item;
    this.rowsSelected.emit(this.selectedItem);
  }

  public handleNonDialogSelection(event: any): void {
    if (this.enableRowClickEvent) {
      const item: any = event.node.data;
      if (event.node.isSelected() && !isEqual(this.selectedItem, item)) {
        this.selectedItem = item;
        this.showSideNav ? this.sidenav.open() : this.navigateToDetailsPage();
        this.rowsSelected.emit(this.selectedItem);
      } else {
        this.hideSidebar();
      }
    }
  }
}
